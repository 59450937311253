<template>
  <div id="index">
    <div class="bg" ref="bgdiv">
      <!-- <div class="video">
        <video autoplay loop muted style="width: 100%;">
          <source src="../assets/bg.mp4" type="video/mp4">
        </video>
      </div> -->
      <div class="chart">
        <div class="right">
          <div class="table-header">
            <div style="width:calc(17% - 1px);text-align: center; border-right: 1px solid #5cbee8;">排名</div>
            <div style="width:calc(66% - 1px);text-align: center; border-right: 1px solid #5cbee8;">项目名称</div>
            <div style="width:17%; text-align: center">最终得分</div>
          </div>
          <div class="dv-scroll-board" style="width: 100%; height: calc(61.3vh - 3px); overflow: hidden; " ref="scrollBox">
            <div class="rows" ref="scrollBoxRows">
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import '../assets/iconfont.css'
var timer1; //这是实时获取数据的定时器
var timer2; //倒计时定时器
export default {
  data() {
    return {
      sw:0,         //search的宽度
      introduceH:'',  //inrroduxe的高度
      style1:'',
      style2:'',
      w:0,         //子元素的宽度和
      min: 0,      //倒计时分钟
      sec: 0,       //倒计时秒针
      time: "",    //用来显示倒计时的时间
      timer: "",   //定时器
      status:0,    //判断是否倒计时  1为开启
      isShow: false,  //是否展示过多的分组


      //---请求所需数据
      contestId:'',  //赛事id
      currentProjectname:'',  //当前在评项目的名字 
      contvotePartNumber:0,  // 当前再评项目的阶段 默认开始为0：转场等待
      currentProjectId:0, //当前在评项目id

      
      projectTime:'', //项目滚动定时器
      projectIndex:0, //项目下标
      projectCurrentList:'', //记录当前项目列表数据

      title:'',

      photoPathUrl : 'http://www.cxcyedu.com/',
    };
  },
  mounted() {
    //获取组别信息，和大赛信息
    this.getGroupData();
  },
  methods: {
    timedown() {
      if (this.min <= 0 && this.sec <= 0) {
        return
      }
      if (this.sec > 0) {
        this.sec--;
      } else {
        this.min--;
        this.sec = 59;
      }
      this.time =
        (this.min >= 10 ? this.min : "0" + this.min) +
        ":" +
        (this.sec >= 10 ? this.sec : "0" + this.sec);
      this.countdown();
    },
    countdown(){
      let dospan = document.getElementById("countdown");
      if(dospan!=null){
        dospan.innerHTML= this.time;
      }
    },
    //下拉菜单按钮切换
    morebt() {
      this.isShow = !this.isShow;
    },
    //弹出错误提示
    openError(text) {
        this.$message.error(text);
    },
    //----------向后台发请求
    //获取组别信息，和大赛信息
		getGroupData() {
        this.$post("/api/votelive/getVoteDetail", {
          contvoteId:this.$route.query.contvoteId,
        }).then((res) => {
          if(!res.result){ //失败  返回错误信息
            this.openError(res.resultMsg)
            clearInterval(timer1)
            return
          }
          else{
            var vote = res.resultData.vote;
            this.title = vote.contvoteName;
            if(vote.contvoteGjBgSource==1){
              const bgdiv = this.$refs.bgdiv;
              bgdiv.style.backgroundImage = "url('"+ photoPathUrl +vote.contvoteGjBgUrl+"')";
            }
            this.loadProject();
          }
        }).catch((res) => {
          console.log(res);
        })
    },
    async loadProject(){
      var data = await this.getProject();
      if(!data.result){ //失败  返回错误信息
        this.openError(data.resultMsg)
      }else{
        this.currentProjectId = data.resultData.currentProj.currentProjectId;
        this.contvotePartNumber = data.resultData.currentProj.contvotePartNumber;
        this.changeprojectList(data.resultData.projectList);

        var that = this;
        //实时获取数据
        clearInterval(timer1);
        timer1 = setInterval(function() {
          that.nextTimeget()
        }, 1000);
      }
    },
    async nextTimeget(){
      let that = this;
      let data = await that.getProject();
      let projList = data.resultData.projectList;
      let projBoo = false;
      //判断项目列表数据有没有发生变化
      for(let i=0;i<projList.length;i++){
        let newProj = projList[i];
        let oldProj = that.projectCurrentList[i];
        if(newProj.voteprojStatus != oldProj.voteprojStatus || newProj.voteprojScore != oldProj.voteprojScore){
          projBoo = true;
          break;
        }
      }
      //当项目数据发生变化或当前项目发生变化，调整当前项目列表
      if(projBoo) that.changeprojectList(projList);
    },
    async getProject(){
      try {
        const res = await this.$post("/api/votelive/getVoteRateProj", {
          contvoteId: this.$route.query.contvoteId,
          groupName: ''
        });
        return res;
      } catch (error) {
        // 处理请求失败的情况
        console.log(error);
        return null;
      }
    },
    changeprojectList(projectList){
      this.projectCurrentList = projectList;

      const wrapper = this.$refs.scrollBoxRows;
      while (wrapper.firstChild) {
        wrapper.removeChild(wrapper.firstChild);
      }
      
      for(var i=0;i<projectList.length;i++){
        if(i<11){
          this.generateProjectHtml(projectList[i],i+1);
        }else{
          break;
        }
      }

      var that = this;
      let boo = false;
      if(this.projectTime) clearInterval(this.projectTime);
      if(projectList.length>10){
        that.projectIndex = 11;
        this.projectTime = setInterval(() => {
          let scrollBoxRows = that.$refs.scrollBoxRows;
          if(boo){
            scrollBoxRows.removeChild(scrollBoxRows.children[0]);
            that.generateProjectHtml(projectList[that.projectIndex], that.projectIndex+1);
            if(projectList.length>that.projectIndex+1){
              that.projectIndex++;
            }else{
              that.projectIndex=0;
            }
          }

          let children = scrollBoxRows.children[0];
          children.style.height = '0';
          boo = true;
        }, 2000);
      }
    },
    generateProjectHtml(project, inx){
      let scrollBoxRows = this.$refs.scrollBoxRows;
      const newChild = document.createElement('div');
      newChild.style="height: 6vh; background-color: #005cab; border-bottom: 1px solid #5cbee8; border-left: 1px solid #5cbee8; border-right: 1px solid #5cbee8;"
      newChild.classList.add("row-item");
      var color = '';
      var score = '';
      var jbei = '';
      let jbeicolor=['gold','silver','darkgoldenrod'];
      
      if(project.voteprojStatus==1 && project.voteprojScore!=null ){ 
        color = 'yellow';
      }else{
        color = '';
      }
      if(project.voteprojStatus==0 || project.voteprojScore==null){
        score='--';
        jbei = '';
      }else{
        score=project.voteprojScore;
        if(inx<=3){
          jbei = '<span class="iconfont icon-jiangbei" style="color:'+jbeicolor[inx-1]+'"></span>';
        }else{
          jbei = '';
        }
      } 

      newChild.innerHTML = '<div align="center" class="ceil" style="width:17%; line-height: 6vh; border-right: 1px solid #5cbee8;">\
                              <div style="font-size:20px; font-family: Times New Roman, Times, serif">No.'+(inx)+jbei+'</div>\
                            </div>\
                            <div class="ceil" style="width: 66%; border-right: 1px solid #5cbee8;">\
                              <div style="font-size:18px; text-align: center; margin-top: 5px; text-overflow: ellipsis;overflow: hidden;">\
                                <span style="color:yellow; font-size:16px;">'+project.contprojOrgName+'</span>\
                                <br/>\
                                '+project.contprojName+'\
                              </div>\
                            </div>\
                            <div class="ceil" style="width: 17%; line-height: 6vh; ">\
                              <div style="font-size:22px;color:'+color+' ;text-align: center;">'+score+'</div>\
                            </div>';
      scrollBoxRows.appendChild(newChild);
    },
    setCurrentTime(data){
      this.min=parseInt(data.leftSec/60)
      this.sec=data.leftSec%60
      this.time =
      (this.min >= 10 ? this.min : "0" + this.min) +
      ":" +
      (this.sec >= 10 ? this.sec : "0" + this.sec);
    },
  },
  watch:{
  },
  beforeDestroy() {
    clearInterval(timer1);
    clearInterval(timer2);
    clearInterval(this.projectTime);
  }

};
</script>


<style scoped>
#index {
  color: #e5ebf8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
}
.bg {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #00154f;
  background-image: url(../assets/gjbj.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
}
.bg div,.bg span{
  font-size: 22px
}
.video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.chart {
  position: absolute;
  display: flex;
  height: 100%;
  padding-top: 13%;
  margin: 0 4% 0 4%; 
  z-index: 1;
  width: 92%;
}
.right {
  width: 100%;
  height: 90%;
}
.right .table-header {
  display: flex;
  height: 5vh;
  line-height: 5vh;
  background-color: #005cab;
  border: 1px solid #5cbee8;
  align-items: center;
  /* justify-content: center; */
  border-radius: 5px 5px 0 0;
}

</style>
